import * as React from "react"
import GradientJumbo from "../components/gradient-jumbo"
import Layout from "../components/layout"
import Contact from "../components/sections/contact"
import Seo from "../components/seo"

const ContatoPage = props => (
  <Layout language="pt" pathname={props.location.pathname}>
    <Seo
      title="Contato"
      lang="pt"
      description="Precisa de ajuda? Podemos te ajudar!"
    />
    <GradientJumbo subtitle="Contato" title="Precisa de Ajuda?" />
    <Contact language="pt" />
  </Layout>
)

export default ContatoPage
